.how-our-services,
.we-created-professional {
  width: 995px;
  position: absolute;
  margin: 0 !important;
  top: 191px;
  left: 80px;
  display: inline-block;
}
.whatsapp-fix{
  width: 50px !important;
  position:fixed !important;
  top: 320px !important;
  left: 10px !important;
  z-index: 999 !important;
}
.how-our-services {
  /* width: 1066px;
  top: 35px;
  font-size: 54px;
  font-weight: 700; */
  font-family: var(--font-syne);
}
/* .how-our-services {
  width: 1066px;
  top: 35px;
  font-size: 54px;
  font-weight: 700;
  font-family: var(--font-syne);
} */
.image,
.we-are-a {
  flex: 1;
  position: relative;
  max-width: 100%;
  z-index: 1;
}
.image {
  border: 0;
  background-color: transparent;
  outline: 0;
  height: 845px;
  overflow: hidden;
  background-image: url(/public/image@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.we-are-a {
  display: inline-block;
}
.ai-chip-artificial-intelligenc-icon {
  height: 421px;
  width: 421px;
  position: relative;
  object-fit: cover;
  display: none;
  max-width: 100%;
}
.frame-child {
  height: 16.5px;
  width: 16.5px;
  position: relative;
  border-radius: var(--br-146xl);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.ai-chip-artificial-intelligenc-parent {
  height: 421px;
  width: 421px;
  margin: 0 !important;
  position: absolute;
  right: -183px;
  bottom: -356px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 157px 17px 156.7px;
  box-sizing: border-box;
  background-image: url(/public/aichipartificialintelligencefuturetechnologyinnovation-1@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
}
.frame-wrapper,
.we-are-a-full-stack-ai-company-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.we-are-a-full-stack-ai-company-parent {
  flex: 1;
  position: relative;
}
.frame-wrapper {
  align-self: stretch;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
}
.generative-ai1 {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-8xl);
}
.generative-ai {
  flex: 1;
  position: relative;
  line-height: 130.3%;
}
.frame-item {
  width: 16.5px;
  height: 16.5px;
  position: relative;
  border-radius: var(--br-146xl);
  overflow: hidden;
  flex-shrink: 0;
}
.frame-wrapper1,
.generative-ai-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper1 {
  flex-direction: column;
  padding: var(--padding-8xs-8) 0 0;
}
.generative-ai-parent {
  width: 190.5px;
  flex-direction: row;
  gap: var(--gap-lgi);
}
.chatbot-and-ai-assistant {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-8xl);
}
.chatbot-and-ai-container {
  flex: 1;
  position: relative;
  line-height: 130.3%;
}
.frame-inner {
  width: 16.5px;
  height: 16.5px;
  position: relative;
  border-radius: var(--br-146xl);
  overflow: hidden;
  flex-shrink: 0;
}
.chatbot-and-ai-assistant-parent,
.frame-div,
.frame-wrapper2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper2 {
  height: 21.3px;
  width: 16.5px;
  flex-direction: column;
  padding: var(--padding-8xs-8) 0 0;
  box-sizing: border-box;
}
.chatbot-and-ai-assistant-parent,
.frame-div {
  width: 295.5px;
}
.chatbot-and-ai-assistant-parent {
  height: 26px;
  flex-direction: row;
  gap: var(--gap-lgi);
}
.frame-div {
  flex-direction: column;
  gap: var(--gap-xl);
}
.aiml-strategy-consulting {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-8xl);
}
.aiml-strategy-consulting-container {
  flex: 1;
  position: relative;
  line-height: 130.3%;
}
.frame-icon {
  width: 16.5px;
  height: 16.5px;
  position: relative;
  border-radius: var(--br-146xl);
  overflow: hidden;
  flex-shrink: 0;
}
.aiml-strategy-consulting-parent,
.frame-wrapper3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper3 {
  height: 21.3px;
  width: 16.5px;
  flex-direction: column;
  padding: var(--padding-8xs-8) 0 0;
  box-sizing: border-box;
}
.aiml-strategy-consulting-parent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-lgi);
}
.ai-integration-and-deployment {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-8xl);
}
.ai-integration-and-container {
  align-self: stretch;
  position: relative;
  line-height: 130.3%;
}
.ai-security1 {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-8xl);
}
.ai-security {
  flex: 1;
  position: relative;
  line-height: 130.3%;
}
.frame-child1 {
  width: 16.5px;
  height: 16.5px;
  position: relative;
  border-radius: var(--br-146xl);
  overflow: hidden;
  flex-shrink: 0;
}
.ai-security-parent,
.frame-wrapper4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper4 {
  height: 21.3px;
  width: 16.5px;
  flex-direction: column;
  padding: var(--padding-8xs-8) 0 0;
  box-sizing: border-box;
}
.ai-security-parent {
  width: 165.5px;
  height: 26px;
  flex-direction: row;
  gap: var(--gap-lgi);
}
.ai-design1 {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-8xl);
}
.ai-design {
  flex: 1;
  position: relative;
  line-height: 130.3%;
}
.frame-child2 {
  width: 16.5px;
  height: 16.5px;
  position: relative;
  border-radius: var(--br-146xl);
  overflow: hidden;
  flex-shrink: 0;
}
.frame-wrapper5 {
  height: 21.3px;
  width: 16.5px;
  flex-direction: column;
  padding: var(--padding-8xs-8) 0 0;
  box-sizing: border-box;
}
.ai-design-parent,
.frame-container,
.frame-group,
.frame-wrapper5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.ai-design-parent {
  width: 151.5px;
  height: 26px;
  flex-direction: row;
  gap: var(--gap-lgi);
}
.frame-container,
.frame-group {
  flex-direction: column;
}
.frame-container {
  width: 312px;
  gap: var(--gap-xl);
  font-size: var(--font-size-xl);
}
.frame-group {
  flex: 1;
  gap: 40px;
  max-width: 100%;
}
.div {
  margin-top: -45px;
  width: 110px;
  position: relative;
  font-size: 120px;
  font-family: var(--font-syne);
  background: linear-gradient(180deg, #fff, rgba(0, 0, 49, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  z-index: 1;
}
.frame-parent {
  width: 737px;
  right: 79px;
  bottom: 133px;
  border-radius: 18px;
  background-color: rgba(43, 134, 170, 0.31);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xl) var(--padding-xl) 46px 21px;
  box-sizing: border-box;
  gap: 34px;
  max-width: 100%;
  font-size: var(--font-size-base);
  color: var(--color-whitesmoke);
}
.frame-parent,
.staffing,
.web-mobile {
  position: absolute;
  margin: 0 !important;
  z-index: 2;
}
.web-mobile {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 392px;
  left: 81px;
  font-size: var(--font-size-7xl);
  font-weight: 700;
  font-family: var(--font-syne);
  color: var(--color-white);
  text-align: left;
  display: inline-block;
}
.staffing {
  bottom: 347px;
  min-width: 112px;
}
.digital-marketing,
.it-consulting,
.staffing {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  left: 81px;
  font-size: var(--font-size-7xl);
  font-weight: 700;
  font-family: var(--font-syne);
  color: var(--color-white);
  text-align: left;
  display: inline-block;
}
.it-consulting {
  position: absolute;
  margin: 0 !important;
  bottom: 272px;
  z-index: 2;
}
.digital-marketing {
  bottom: 197px;
}
.artificial-intelligence,
.digital-marketing,
.line-icon {
  position: absolute;
  margin: 0 !important;
  z-index: 2;
}
.artificial-intelligence {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 317px;
  left: 81px;
  font-size: var(--font-size-7xl);
  font-weight: 700;
  font-family: var(--font-syne);
  color: rgba(255, 255, 255, 0.32);
  text-align: left;
  display: inline-block;
}
.line-icon {
  height: 7.4px;
  width: 34px;
  top: 332.5px;
  left: 378px;
}
.we-created-professional-servic-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}
.image-wrapper,
.new-section,
.new-section-inner {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.new-section-inner {
  margin-left: -1px;
  align-self: stretch;
  background-color: #12171a;
  flex-direction: column;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-dm-sans);
}
.image-wrapper,
.new-section {
  background-color: var(--color-white);
}
.image-wrapper {
  margin-left: -9px;
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
  max-width: 102%;
}
.new-section {
  width: 100%;
  position: relative;
  flex-direction: column;
  letter-spacing: normal;
  line-height: normal;
}
@media screen and (max-width: 750px) {
  .how-our-services {
    font-size: 43px;
  }
  .div {
    font-size: 48px;
  }
}
@media screen and (max-width: 450px) {
  .we-created-professional {
    font-size: var(--font-size-base);
  }
  .how-our-services {
    font-size: var(--font-size-13xl);
  }
  .ai-design,
  .ai-integration-and-container,
  .ai-security,
  .aiml-strategy-consulting-container,
  .chatbot-and-ai-container,
  .generative-ai {
    font-size: var(--font-size-base);
    line-height: 21px;
  }
  .frame-group {
    min-width: 100%;
  }
  .div {
    font-size: 30px;
  }
  .artificial-intelligence,
  .digital-marketing,
  .it-consulting,
  .staffing,
  .web-mobile {
    font-size: var(--font-size-2xl);
  }
}
