@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-dm-sans: "DM Sans";
  --font-syne: Syne;

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-base: 16px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --font-size-15xl: 34px;
  --font-size-13xl: 32px;
  --font-size-51xl: 70px;
  --font-size-23xl: 42px;
  --font-size-37xl: 56px;
  --font-size-2xl: 21px;
  --font-size-xl: 20px;

  /* Colors */
  --color-white: #fff;
  --color-gainsboro: #dfdfdf;
  --color-steelblue-100: #2b86aa;
  --color-whitesmoke: #eaeaea;

  /* Gaps */
  --gap-28xl: 47px;
  --gap-4xl: 23px;
  --gap-mini: 15px;
  --gap-xl: 20px;
  --gap-lgi: 19px;

  /* Paddings */
  --padding-2xs: 11px;
  --padding-43xl: 62px;
  --padding-80xl: 99px;
  --padding-46xl: 65px;
  --padding-7xl: 26px;
  --padding-xl: 20px;
  --padding-8xl: 27px;
  --padding-21xl: 40px;
  --padding-30xl: 49px;
  --padding-23xl: 42px;
  --padding-smi: 13px;
  --padding-12xs: 1px;
  --padding-10xs: 3px;
  --padding-8xs-8: 4.8px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-146xl: 165px;
}

.automation-programmer-with-3d-icon {
  align-self: stretch;
  width: 1458px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  display: none;
  min-height: 786px;
  max-width: 100%;
}
.rectangle-div {
  /* width: 786px; */
  height: calc(100% + 115.5px);
  position: absolute;
  margin: 0 !important;
  top: 0;
  bottom: -115.5px;
  left: 890.5px;
  background: linear-gradient(180deg, rgba(33, 30, 109, 0), #211e6d 81.26%);
  transform: rotate(90deg);
  transform-origin: 0 0;
  z-index: 1;
}
.bignews{
  padding-bottom: 150px !important;
  padding-top: 100px !important;
}
.big-news {
  flex: 1;
  position: relative;
  display: inline-block;
  max-width: 100%;
  z-index: 2;
}
.big-news-wrapper {
  width: 387px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-10xs);
  box-sizing: border-box;
  max-width: 100%;
}
.artificial-intelligent-service {
  position: absolute;
  top: 45px;
  left: 155px;
  background-color: var(--color-steelblue-100);
  /* width: 266px; */
  height: 39px;
  z-index: 2;
}
.generative-ai2 {
  font-size: var(--font-size-15xl);
  color: var(--color-white);
}
.we-are-thrilled-container {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre-wrap;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.significant-release {
  align-self: stretch;
  height: 87px;
  position: relative;
}
.this-cutting-edge-technology {
  flex: 1;
  position: relative;
  line-height: 30px;
  display: inline-block;
  max-width: 100%;
  z-index: 2;
}
.generative-technology,
.this-cutting-edge-technology-r-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.this-cutting-edge-technology-r-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
}
.generative-technology {
  width: 479px;
  flex-direction: column;
  gap: var(--gap-mini);
  font-size: var(--font-size-13xl);
  color: var(--color-gainsboro);
  font-family: var(--font-dm-sans);
}
.artificial-intelligent-service1 {
  width: 237px;
  position: relative;
  font-size: var(--font-size-lg);
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
}
.button,
.image1,
.rectangle-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) var(--padding-21xl);
  background-color: transparent;
  border-radius: var(--br-5xs);
  background: linear-gradient(180deg, #006db5, #02dbec 97.51%);
  flex-direction: row;
  white-space: nowrap;
  z-index: 2;
}

.image1,
.rectangle-parent {
  box-sizing: border-box;
  max-width: 100%;
}
.rectangle-parent {
  width: 890.5px;
  flex-direction: column;
  padding: var(--padding-43xl) var(--padding-80xl) var(--padding-46xl);
  position: relative;
  gap: var(--gap-28xl);
}
.image1 {
  margin-left: -2px;
  flex: 1;
  flex-direction: row;
  padding: 0 var(--padding-2xs);
  background-image: url(/public/Assets/aibackgrounds.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  flex-shrink: 0;
  debug_commit: 1de1738;
  text-align: left;
  font-size: var(--font-size-51xl);
  color: var(--color-white);
  font-family: var(--font-syne);
}
@media screen and (max-width: 750px) {
  .big-news {
    font-size: var(--font-size-37xl);
  }
  .we-are-thrilled-container {
    font-size: var(--font-size-7xl);
  }
  .rectangle-parent {
    gap: var(--gap-4xl);
    padding: var(--padding-21xl) var(--padding-30xl) var(--padding-23xl);
    box-sizing: border-box;
  }
}
.fontsynecls{
    font-family: var(--font-syne);
}
@media screen and (max-width: 320px) {
  .button {
    padding: var(--padding-smi) var(--padding-xl);
}
}
@media screen and (max-width: 450px) {
  .big-news {
    font-size: var(--font-size-23xl);
  }
  .we-are-thrilled-container {
    font-size: var(--font-size-lgi);
  }
  .rectangle-parent {
    padding: var(--padding-7xl) var(--padding-xl) var(--padding-8xl);
    box-sizing: border-box;
  }
}

.backimageai{
  background-image: url("../Home/ai.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 435px;
}
.backimageweb{
  background-image: url("../Home/webdevelopment.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 435px;
}
.backimagestaffing{
  background-image: url("../Home/staffing.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 435px;
}
.backimageitconsulting{
  background-image: url("../Home/itconsulting.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 435px;
}
.backimagedigital{
  background-image: url("../Home/digitalmarketing.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 435px;
}
.tabsecbackimg{
  background-image: url("../Home/aibackground.png");
  background-repeat: no-repeat;
  background-color: #12171a;
}
